var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"d-flex mx-0"},[_c('div',{staticClass:"col-auto px-0 mt-2"},[_c('div',{staticClass:"btn-back",on:{"click":function($event){return _vm.$router.push({name: 'admin.tesoreria.cajas'})}}},[_c('i',{staticClass:"icon-left f-14"})])]),_c('div',{staticClass:"col"},[_c('tabla-general',{attrs:{"data":_vm.dataTable,"columnas":_vm.dataColumns,"class-header":"text-general f-16","alto":"calc(100vh - 176px)"}},[_c('template',{slot:"cabecera-izquierda"},[_c('div',{staticClass:"col-auto f-600 text-general f-18"},[_vm._v(" Lista de cajas fuertes ")]),_c('div',{staticClass:"col d-middle"},[(_vm.$can('botones_flujo_dinero_cajas_gestionar'))?_c('div',{staticClass:"btn-general px-3",staticStyle:{"height":"32px"},on:{"click":_vm.crearCaja}},[_vm._v(" Añadir caja ")]):_vm._e()])]),_c('template',{slot:"adicionales-derecha"},[_c('el-table-column',{attrs:{"label":"Saldo","prop":"saldo","sortable":"","sort-by":"saldo","align":"center","class-name":"text-right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.separadorNumero(row.saldo))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Verificación","prop":"verificacion_saldo","sortable":"","sort-by":"verificacion_saldo","align":"center","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.verificacion_saldo)+" Hrs ")]}}])}),_c('el-table-column',{attrs:{"label":"Descripción","prop":"descripcion","sortable":"","sort-by":"descripcion","width":"250"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"created_at","label":"Creación","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_vm._v(" "+_vm._s(_vm.formatearFecha(row.created_at))+" ")])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('el-tooltip',{attrs:{"placement":"bottom","content":!row.estado ? 'Activar' : 'Inactivar',"effect":"light"}},[_c('el-switch',{attrs:{"active-color":"#28D07B","disabled":!_vm.$can('botones_flujo_dinero_cajas_gestionar'),"inactive-color":"#DBDBDB"},on:{"change":function($event){return _vm.cambioEstadoCaja(row)}},model:{value:(row.estado),callback:function ($$v) {_vm.$set(row, "estado", $$v)},expression:"row.estado"}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"placement":"bottom","content":"Eliminar Caja","effect":"light"}},[(_vm.$can('botones_flujo_dinero_cajas_gestionar'))?_c('div',{staticClass:"btn-action border",on:{"click":function($event){return _vm.eliminar(row)}}},[_c('i',{staticClass:"icon-delete-outline f-20 cr-pointer text-general"})]):_vm._e()])]}}])}),_c('el-table-column',{staticClass:"cr-pointer",attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"placement":"bottom","content":"Editar caja","effect":"light"}},[(_vm.$can('botones_flujo_dinero_cajas_gestionar'))?_c('div',{staticClass:"btn-action border",on:{"click":function($event){return _vm.editarCaja(row)}}},[_c('i',{staticClass:"icon-pencil-outline f-17 text-general cr-pointer"})]):_vm._e()])]}}])})],1)],2)],1)]),_c('modal-crear-caja',{ref:"modalCrearCaja",on:{"crear":_vm.crear}}),_c('modal-editar-caja',{ref:"modalEditarCaja",on:{"editar":_vm.editar}}),_c('modal-eliminar',{ref:"modalEliminar",attrs:{"titulo":"Eliminar caja","mensaje":"¿Desea eliminar esta caja?"},on:{"eliminar":_vm.eliminarCaja}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }