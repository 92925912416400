import { render, staticRenderFns } from "./editarCaja.vue?vue&type=template&id=9df37994&scoped=true&"
import script from "./editarCaja.vue?vue&type=script&lang=js&"
export * from "./editarCaja.vue?vue&type=script&lang=js&"
import style0 from "./editarCaja.vue?vue&type=style&index=0&id=9df37994&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9df37994",
  null
  
)

export default component.exports