<template>
    <section>
        <div class="d-flex mx-0">
            <div class="col-auto px-0 mt-2">
                <div class="btn-back" @click="$router.push({name: 'admin.tesoreria.cajas'})">
                    <i class="icon-left f-14" />
                </div>
            </div>
            <div class="col">
                <tabla-general :data="dataTable" :columnas="dataColumns" class-header="text-general f-16" alto="calc(100vh - 176px)">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto f-600 text-general f-18">
                            Lista de cajas fuertes
                        </div>
                        <div class="col d-middle">
                            <div v-if="$can('botones_flujo_dinero_cajas_gestionar')" class="btn-general px-3" style="height:32px;" @click="crearCaja">
                                Añadir caja
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column label="Saldo" prop="saldo" sortable sort-by="saldo" align="center" class-name="text-right">
                            <template slot-scope="{ row }">
                                {{ separadorNumero(row.saldo) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Verificación" prop="verificacion_saldo" sortable sort-by="verificacion_saldo" align="center" class-name="text-center">
                            <template slot-scope="{ row }">
                                {{ row.verificacion_saldo }} Hrs
                            </template>
                        </el-table-column>
                        <el-table-column label="Descripción" prop="descripcion" sortable sort-by="descripcion" width="250" />
                        <el-table-column sortable prop="created_at" label="Creación" class-name="text-center">
                            <template slot-scope="{ row }">
                                <div class="row mx-0 justify-center">
                                    {{ formatearFecha(row.created_at) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ row }">
                                <div class="row mx-0 justify-center">
                                    <el-tooltip placement="bottom" :content="!row.estado ? 'Activar' : 'Inactivar'" effect="light">
                                        <el-switch v-model="row.estado" active-color="#28D07B" :disabled="!$can('botones_flujo_dinero_cajas_gestionar')" inactive-color="#DBDBDB" @change="cambioEstadoCaja(row)" />
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="80">
                            <template slot-scope="{ row }">
                                <el-tooltip placement="bottom" content="Eliminar Caja" effect="light">
                                    <div v-if="$can('botones_flujo_dinero_cajas_gestionar')" class="btn-action border" @click="eliminar(row)">
                                        <i class="icon-delete-outline f-20 cr-pointer text-general" />
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column width="80" class="cr-pointer">
                            <template slot-scope="{ row }">
                                <el-tooltip placement="bottom" content="Editar caja" effect="light">
                                    <div v-if="$can('botones_flujo_dinero_cajas_gestionar')" class="btn-action border" @click="editarCaja(row)">
                                        <i class="icon-pencil-outline f-17 text-general cr-pointer" />
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-caja ref="modalCrearCaja" @crear="crear" />
        <modal-editar-caja ref="modalEditarCaja" @editar="editar" />
        <modal-eliminar ref="modalEliminar" titulo="Eliminar caja" mensaje="¿Desea eliminar esta caja?" @eliminar="eliminarCaja" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Cajas from "~/services/flujoDinero/cajas";
export default {
    components: {
        modalCrearCaja: () => import('./partials/modalCrearCaja'),
        modalEditarCaja: () => import('./partials/modalEditarCaja'),
    },
    data(){
        return {
            dataTable: [],
            dataColumns: [
                {valor: 'nombre', titulo: 'Nombre', class: '', ancho: '100' },
                {valor: 'cedis_count', titulo: 'Cedis', class: 'text-center', ancho: '50' },
            ],
            idCaja: null
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda == null){
                    setTimeout(async() => {
                        await this.tablaCajas()
                    }, 1000);
                }else{
                    await this.tablaCajas()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async tablaCajas(){
            try {
                const params = {
                    id_moneda: this.id_moneda,
                }
                const { data } = await Cajas.tablaCajas(params)
                this.dataTable = data.data.cajas
            } catch (error){
                this.error_catch(error)
            }
        },
        crearCaja(){
            this.$refs.modalCrearCaja.toggle()
        },
        editarCaja(model){
            this.$refs.modalEditarCaja.toggle(model)
        },
        crear(model){
            this.dataTable.push(model)
        },
        editar(model){
            let index = this.dataTable.findIndex(el => el.id == model.id)
            this.$set(this.dataTable, index, model)
        },
        eliminar({ id }){
            this.idCaja = id
            this.$refs.modalEliminar.toggle();
        },
        async eliminarCaja(){
            try {
                const {data} = await Cajas.eliminarCaja(this.idCaja)
                this.notificacion('', data.message, 'success')
                let index = this.dataTable.findIndex(el => el.id == this.idCaja)
                this.dataTable.splice(index, 1)
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioEstadoCaja({id}){
            try {
                const {data} = await Cajas.cambioEstadoCaja(id)
                this.notificacion('', data.message, 'success')
                // let index = this.dataTable.findIndex(el => el.id == model.id)
                // this.$set(this.dataTable, index, model)
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-caja{
    width: 24px;
    height: 24px;
    background-color: #FDFDFD;
}
</style>
